import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { AppBar, Avatar, Button, Icon, IconSize, IconType, Menu, Tooltip, } from "@dexibit/core";
import { faGrid, faInfo, faMessage, faSignOut, faUpRightFromSquare, faUserSecret, } from "@fortawesome/pro-regular-svg-icons";
import appConfig from "../../config";
import { useAppSelector } from "../../store";
import { selectLogoutUri } from "../../store/services/config";
import { intercomHandler } from "../../utils/intercom";
import tokenManager from "../../utils/tokenManager";
import { HeaderAccountInfo, HeaderHeading, HeaderProfile, HeaderProfileInfo, HeaderProfileInfoName, HeaderRightContent, HeaderTitle, StyledIcon, StyledOverlay, Unread, } from "./styles";
import { useSelector } from "react-redux";
import { selectUnreadCount } from "../../store/services/intercom";
const Header = (props) => {
    const { userInfo, activeLink, accountName, venueName, dialogVisible } = props;
    const logoutUri = useAppSelector(selectLogoutUri);
    const isImpersonating = tokenManager.isImpersonationMode();
    const isDashboard = (activeLink === null || activeLink === void 0 ? void 0 : activeLink.to) === "/v2/dashboard";
    const unreadCount = useSelector(selectUnreadCount);
    const initials = userInfo && userInfo.given_name && userInfo.family_name
        ? `${userInfo.given_name.charAt(0)}${userInfo.family_name.charAt(0)}`
        : undefined;
    const profileName = (userInfo === null || userInfo === void 0 ? void 0 : userInfo.name) || (userInfo === null || userInfo === void 0 ? void 0 : userInfo.email);
    const handleLogout = () => {
        tokenManager.removeToken();
        intercomHandler.shutdownIntercom();
        if (!logoutUri)
            return;
        window.location.href = logoutUri;
    };
    // TODO add this back when routing to IM is fixed
    // See https://dexibit.atlassian.net/browse/DASH-1635
    // const handleEditProfile = () => {
    //   navigate(`${config?.rootPath}/administration`, {
    //     state: {
    //       path: "/users/",
    //       id: userInfo?.sub,
    //     },
    //   });
    // };
    const onSupportClick = () => {
        window.open(appConfig.supportCentreUrl, "_blank");
    };
    const ProfileMenuAnchor = (props) => (_jsxs(HeaderProfile, Object.assign({ id: "profile-menu-button", "data-cy": "profile-menu-button", "aria-label": "Profile", variant: "uncontained" }, props, { children: [_jsxs(HeaderProfileInfo, { children: [profileName && (_jsxs(HeaderProfileInfoName, { isImpersonating: isImpersonating, children: [isImpersonating && (_jsx(Tooltip, { title: `You are impersonating user: ${profileName}`, children: _jsx("span", { children: _jsx(StyledIcon, { type: IconType.fa, icon: faUserSecret }) }) })), profileName] })), (userInfo === null || userInfo === void 0 ? void 0 : userInfo.account_name) && (_jsxs(HeaderAccountInfo, { isImpersonating: isImpersonating, children: [venueName && !isDashboard ? `${venueName} • ` : "", accountName] }))] }), _jsx(Avatar, { imageSrc: userInfo === null || userInfo === void 0 ? void 0 : userInfo.picture, initials: initials, alt: "User avatar" })] })));
    return (_jsxs(_Fragment, { children: [_jsx(AppBar, { leftContent: !isDashboard ? (_jsxs(HeaderHeading, { children: [_jsx(Icon, { icon: (activeLink === null || activeLink === void 0 ? void 0 : activeLink.icon) || faGrid, size: IconSize.lg, type: IconType.fa }), _jsx(HeaderTitle, { "data-cy": "header-title", children: (activeLink === null || activeLink === void 0 ? void 0 : activeLink.fullTitle) || "Dexibit" })] })) : undefined, rightContent: userInfo && (_jsxs(HeaderRightContent, { children: [_jsx(Menu, { id: "profile-menu", buttonComponent: ProfileMenuAnchor, anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            }, transformOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }, items: [
                                // TODO add this back when routing to IM is fixed
                                // See https://dexibit.atlassian.net/browse/DASH-1635
                                // {
                                //   id: "edit-profile",
                                //   label: "Edit profile",
                                //   icon: <Icon type="fa" icon={faAddressCard} />,
                                //   onClick: handleEditProfile,
                                //   "data-cy": "edit-profile-button",
                                // },
                                {
                                    id: "logout",
                                    label: "Log out",
                                    startAdornment: [
                                        _jsx(Icon, { type: IconType.fa, icon: faSignOut }, "logout-icon"),
                                    ],
                                    onClick: handleLogout,
                                },
                            ] }), _jsx(Menu, { id: "header-menu", buttonComponent: (props) => (_jsx(Button, Object.assign({ id: "header-menu-button", ariaLabel: "Help menu", variant: "secondary", style: {
                                    width: "20px",
                                    height: "20px",
                                    padding: "0.85rem",
                                    alignSelf: "center",
                                }, isIconButton: true }, props, { children: _jsx(Icon, { type: IconType.fa, icon: faInfo }) }))), transformOrigin: {
                                horizontal: "right",
                                vertical: "top",
                            }, anchorOrigin: {
                                horizontal: "right",
                                vertical: "bottom",
                            }, items: [
                                {
                                    id: "support",
                                    startAdornment: [
                                        _jsx(Icon, { type: IconType.fa, icon: faUpRightFromSquare }, "support-icon"),
                                    ],
                                    label: "Support center",
                                    onClick: onSupportClick,
                                },
                                {
                                    id: "support_messenger",
                                    startAdornment: [
                                        _jsx(Icon, { type: IconType.fa, icon: faMessage }, "support-messenger-icon"),
                                    ],
                                    label: "Support messenger",
                                    endAdornment: unreadCount
                                        ? [_jsx(Unread, { children: unreadCount }, "unread")]
                                        : [],
                                },
                            ] })] })) }), dialogVisible && _jsx(StyledOverlay, {})] }));
};
export default Header;
