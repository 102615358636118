// Same as old vaadin implementation
const UPDATE_INTERVAL = 2 * 60 * 1000; // 2 minutes ping time
class IntercomHandler {
    updateIntercomInterval() {
        this.interval = setInterval(() => {
            window.Intercom("update");
        }, UPDATE_INTERVAL);
    }
    shutdownIntercom() {
        clearInterval(this.interval);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window.Intercom) {
            window.Intercom("shutdown");
        }
    }
    onUnreadCountChange(setCount) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window.Intercom) {
            window.Intercom("onUnreadCountChange", (count) => setCount(count));
        }
    }
}
export const intercomHandler = new IntercomHandler();
