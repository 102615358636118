var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import "./index.css";
import { StrictMode } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { gaeaThemeLight, ThemeProvider } from "@dexibit/styles";
import App from "./App";
import ErrorFallback from "./components/ErrorFallback";
import { NavigationHelmet } from "./NavigationHelmet";
import { store } from "./store/store";
import logger from "./utils/logger";
import initDatadog from "./utils/logger/datadog";
import tokenManager from "./utils/tokenManager";
import toUserContext from "./utils/toUserContext";
// Ensure to initialize datadog as early as possible
initDatadog();
if (!APP_LD_CLIENT_SIDE_ID) {
    throw new Error("LaunchDarkly client-side ID not found in environment variables");
}
void (() => __awaiter(void 0, void 0, void 0, function* () {
    const container = document.getElementById("root");
    if (!container) {
        throw new Error("Could not find root container");
    }
    const root = createRoot(container);
    const userData = tokenManager.getUserData();
    const context = toUserContext(userData);
    const launchDarklyConfig = {
        clientSideID: APP_LD_CLIENT_SIDE_ID,
        options: {
            // Use our logger so it gets to our puppy DataDog as well
            logger,
        },
        context,
    };
    const LDProvider = yield asyncWithLDProvider(launchDarklyConfig);
    root.render(_jsx(StrictMode, { children: _jsx(HelmetProvider, { children: _jsx(ThemeProvider, { theme: gaeaThemeLight, children: _jsxs(Provider, { store: store, children: [_jsx(NavigationHelmet, {}), _jsx(BrowserRouter, { children: _jsx(LDProvider, { children: _jsx(ErrorBoundary, { FallbackComponent: ErrorFallback, onError: (error) => logger.error("Global error", error), children: _jsx(App, {}) }) }) })] }) }) }) }));
}))();
