import { Button, Icon } from "@dexibit/core";
import { FONT, GAEA_COLOURS, PALETTE_LIGHT, SPACING, styled } from "@dexibit/styles";
export const HeaderHeading = styled("div") `
  display: flex;
  align-items: center;
`;
export const HeaderTitle = styled("h2") `
  margin: 0;
  margin-left: ${SPACING.large};
  font-weight: ${FONT.BODY_REGULAR.fontWeight};
  font-size: ${FONT.BODY_REGULAR.fontSize};
`;
export const HeaderProfile = styled(Button) `
  display: flex;
  align-items: center;
  text-align: right;
  margin-right: ${SPACING.large};
  padding: 0;
  font-size: ${FONT.BODY_REGULAR.fontSize};
  :hover {
    background-color: unset;
  }
  :focus {
    background-color: unset;
  }
`;
export const HeaderProfileInfo = styled("div") `
  margin-right: ${SPACING.small};
`;
export const HeaderAccountInfo = styled("div", {
    shouldForwardProp: (prop) => prop !== "isImpersonating",
})(({ isImpersonating }) => `
  color: ${isImpersonating ? PALETTE_LIGHT.PRIMARY_BASE : PALETTE_LIGHT.TEXT_EMPHASIS_REGULAR}
`);
export const StyledIcon = styled(Icon) `
  margin-right: ${SPACING.regular};
`;
export const HeaderProfileInfoName = styled("div", {
    shouldForwardProp: (prop) => prop !== "isImpersonating",
})(({ isImpersonating }) => `
  color: ${isImpersonating ? PALETTE_LIGHT.PRIMARY_BASE : PALETTE_LIGHT.TEXT_EMPHASIS_HIGH};
  line-height: ${FONT.BODY_REGULAR.lineHeight};
`);
export const HeaderRightContent = styled("div") `
  display: flex;
  justify-content: flex-end;
`;
export const StyledOverlay = styled("div") `
  width: 100%;
  height: 57px;
  background-color: rgba(0, 0, 0, 0.5);
  //background-color: ${PALETTE_LIGHT.BACKGROUND_DIALOG};
  z-index: 5;
  position: fixed;
  top: 0;
`;
export const Unread = styled("span") `
  border-radius: 50%;
  background-color: ${GAEA_COLOURS.UTILITY.RED.RED};
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
`;
